import getSessionData from './getSessionData';
import { onBootComplete } from './onBootComplete';
import { onBootError } from './onBootError';
import { setApp } from 'owa-config/lib/bootstrapOptions';
import createBootError from './createBootError';
import { trackBottleneck, markEnd, markStart, markFunction } from 'owa-performance';
import type StartConfig from './interfaces/StartConfig';
import { setAriaTenantToken } from './ariaUtils';
import { getOwsPath, getOpxHostData } from 'owa-config';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * The msal encryption cookie is used for reusing tokens across sessions to help PLT. This will not be deprecated.
 *	> 'owa-config/lib/universalCookies' import is restricted from being used. The long term plan is to deprecate the cookie so we should only be using this sparingly */
import { getCookie } from 'owa-config/lib/universalCookies';
import getScopedPath from 'owa-url/lib/getScopedPath';
import { updateServiceConfig, getConfig } from 'owa-service/lib/config';
import { setIsDeepLink } from 'owa-url/lib/isDeepLink';
import type { PromiseWithKey } from 'owa-performance';
import { unblockLazyLoadCallbacks } from 'owa-bundling-light';
import { hasQueryStringParameter } from 'owa-querystring';
import { setBootFailureCount } from './bootErrorCounter';
import { setThreadName } from 'owa-thread-config';
import type { MailboxInfo } from 'owa-client-types';
import {
    lazyGetAnchorMailbox,
    lazyGetAuthTokenMsal,
    lazyCreateMsalInstance,
    lazyOnActivityTimeoutErrorForMSAL,
    lazyAcquireAccessTokenMsal,
} from 'owa-msaljs/lib/lazyFunctions';
import type { HeadersWithoutIterator } from 'owa-service/lib/RequestOptions';
import { initializeCssInjection } from 'owa-inject-css';
import { preloadFonts } from './preloadFonts';
import { preloadSuiteUx } from './preloadSuiteUx';
import { pwOnBootComplete } from 'owa-playwright';
import { isMonitoringProbe } from 'owa-msaljs/lib/isMonitoringProbe';
import { isMsalFlowEnabled } from 'owa-msaljs/lib/isMsalFlowEnabled';
import { yieldNow } from 'owa-task-queue/lib/schedule';
import { setAuthTiming } from 'owa-auth-timings';

let startTime: number;

export function sharedStart(config: StartConfig): Promise<any> {
    return internalStart(config)
        .then(() => completeStart(config))
        .catch(bootError => onBootError(bootError, config));
}

async function internalStart(config: StartConfig) {
    try {
        startTime = Date.now();

        // Set the thread name for the current thread
        setThreadName('MAIN_THREAD');
        setApp(config.app);
        setIsDeepLink(!!config.isDeepLink);
        setAriaTenantToken(config.startupAriaToken);

        // NOTE: This is for TESTING ONLY.
        if (process.env.NODE_ENV !== 'production' && hasQueryStringParameter('testsupportonboot')) {
            throw new Error('Test fail');
        }

        // Initialize CSS injection
        initializeCssInjection();

        updateServiceConfig({
            baseUrl: getScopedPath(getOwsPath()),
        });

        if (config.runBeforeStart) {
            markStart('rbsp');
            await config.runBeforeStart(config).catch(error => {
                if (!error.source) {
                    error.source = 'BeforeBoot';
                }
                throw error;
            });
            markEnd('rbsp');
        }

        let authToken: string | undefined = undefined;
        // MSAL creation needs to happen *after* runBeforeStart; in hosted scenarios TeamsJS
        // is initialized in runBeforeStart and sets up the NAA bridge that MSAL uses during init.
        // MSAL should not overwrite any existing getAuthToken callback if one was set up in runBeforeStart,
        // for example hosted auth that relies on Hub SDK and does not yet support MSAL.
        if (isMsalFlowEnabled() && !getConfig().getAuthToken) {
            markStart('msal');
            if (getCookie('msal.cache.encryption')) {
                setAuthTiming('crypt');
            }
            setAuthTiming('msalis');
            // Create the MSAL instance with the correct app-specific parameters here in order to
            // be able to make the startupdata request before we start evaluating other boot bundles
            await lazyCreateMsalInstance.importAndExecute(config.msalConfiguration);
            setAuthTiming('msalie');

            setAuthTiming('fgatmsals');
            authToken = await lazyGetAuthTokenMsal.importAndExecute();
            setAuthTiming('fgatmsalsc');

            const getAuthToken = (headers?: HeadersWithoutIterator, mailboxInfo?: MailboxInfo) =>
                lazyGetAuthTokenMsal.importAndExecute(headers, mailboxInfo);

            const getMsalToken = (
                mailboxInfo: MailboxInfo,
                resource?: string,
                scope?: string,
                correlationId?: string,
                wwwAuthenticateHeader?: string
            ) =>
                lazyAcquireAccessTokenMsal.importAndExecute(
                    mailboxInfo,
                    resource,
                    scope,
                    undefined /* headers */,
                    correlationId,
                    wwwAuthenticateHeader
                );

            updateServiceConfig({
                getMsalToken,
                getAuthToken,
                getAnchorMailbox: (mailboxInfo?: MailboxInfo) =>
                    lazyGetAnchorMailbox.importAndExecute(mailboxInfo),
                onAuthFailed: () => {},
                onActivityTimeoutError: () => lazyOnActivityTimeoutErrorForMSAL.importAndExecute(),
                isMsalFlowEnabled: true,
            });
            markEnd('msal');
        }

        const sessionDataPromise =
            config.overrideBootPromises?.(authToken) ?? getSessionData(authToken);

        if (config.overrideBootPromises) {
            // make sure sessiondata can send network calls and trigger postMessage's if needed
            await yieldNow('overrideBootPromises');
        }

        const javascriptPromise = markFunction(config.bootstrap.import, 'mjs')();
        const fontPromise = preloadFonts();

        const bootPromises: PromiseWithKey<any>[] = [
            { promise: sessionDataPromise, key: 'sd' },
            { promise: javascriptPromise, key: 'js' },
            { promise: fontPromise, key: 'fonts' },
        ];

        if (!config?.disableFeature?.bposPreRenderPromise) {
            bootPromises.push({
                promise: preloadSuiteUx(),
                key: 'suiteux',
            });
        }

        const opxHostPromise = getOpxHostData();
        const bootstrapPromises: Promise<any>[] = [javascriptPromise];
        if (opxHostPromise) {
            bootstrapPromises.push(opxHostPromise);
        }

        const strategies = config.strategies;
        bootPromises.push({
            promise: Promise.all(bootstrapPromises)
                .then(([bootstrap]) => {
                    try {
                        return bootstrap(sessionDataPromise, strategies);
                    } catch (e) {
                        throw createBootError(e, 'Bootstrap');
                    }
                })
                .catch(e => {
                    throw createBootError(e, 'Script');
                }),
            key: null,
        });

        if (config.runAfterRequests) {
            // make sure boot promises can send network calls
            await yieldNow('runAfterRequests');
            config.runAfterRequests(sessionDataPromise);
        }

        if (strategies) {
            // start downloading the boot strategies right away
            for (const lazyAction of Object.values(strategies)) {
                if (lazyAction) {
                    lazyAction.import();
                }
            }
        }

        return trackBottleneck('start', bootPromises);
    } catch (e) {
        throw createBootError(e, 'Preboot');
    }
}

async function completeStart(config: StartConfig) {
    try {
        onBootComplete(config, startTime);

        // we still want to call on loader removed
        // if there was no loader at all
        config.onLoaderRemoved?.();
        if (!config?.skipUnblockLazyLoadCallbacks?.()) {
            unblockLazyLoadCallbacks();
        }
        setBootFailureCount(0);

        // call Playwright
        if (isMonitoringProbe()) {
            pwOnBootComplete();
        }
    } catch (e) {
        throw createBootError(e, 'BootComplete');
    }
}
